<script setup lang="ts">
import gsap from 'gsap';
import EmailLink from 'src/components/uikit/EmailLink.vue';
// import { useRenderLoop } from '@tresjs/core';

// import { useMenuItemsAnime } from './useMenuItemsAnime';

import { menuItems } from 'src/data/menu';

const title = ':PtBurg* • Арт студия • Дизайн и разработка';
const description =
  'Создаем решения реального времени c 3D и анимацией. Интернет – это искусство.';

// const description =
//   'PtBurg Art Studio — создаем интерактивные 3D-решения и анимацию для бизнеса и развлечений. Превращаем технологии в искусство: креативный дизайн, динамичная визуализация и инновации в реальном времени. Ваш цифровой проект — наш шедевр!';

const image = 'https://ptburg.com/og-image.png';

useMeta(() => ({
  title,
  meta: {
    // Основные мета-теги
    description: { name: 'description', content: description },
    // keywords: { name: 'keywords', content: articleKeywords.value },

    // Open Graph
    ogTitle: { property: 'og:title', content: title },
    ogDescription: {
      property: 'og:description',
      content: description,
    },
    ogImage: {
      property: 'og:image',
      content: image,
    },
    // Twitter Cards
    twitterCard: { name: 'twitter:card', content: 'summary_large_image' },
    twitterTitle: { name: 'twitter:title', content: title },
    twitterDescription: {
      name: 'twitter:description',
      content: description,
    },
    twitterImage: { name: 'twitter:image', content: image },
  },
}));

// const router = useRouter();
// const $q = useQuasar();
// const menuItemsAnime = useMenuItemsAnime();
const { width } = useWindowSize();
// const { onLoop } = useRenderLoop();

// const authStore = useAuthStore();

// const leftDrawerOpen = ref(false);

// function toggleLeftDrawer() {
//   leftDrawerOpen.value = !leftDrawerOpen.value;
// }

const { showCookieNotification } = useCookieConsent();

const rightDrawerRef = ref();

const isRightDrawerOpen = ref(false);

onMounted(() => {
  showCookieNotification();
});

const rightDrawerToggle = () => {
  rightDrawerRef.value.toggle();
};

watch(isRightDrawerOpen, (val) => {
  if (val) {
    gsap.fromTo(
      '.drawer-menu .char',
      {
        'will-change': 'opacity, transform',
        opacity: 0,
        rotationX: 120,
        rotationY: -90,
        translateX: -80,
      },
      {
        ease: 'expo.out',
        opacity: 1,
        rotationX: 0,
        rotationY: 0,
        translateX: 0,
        stagger: {
          each: 0.03,
          from: 0,
        },
      },
    );
  }
});

const menuItemMouseEnter0 = (event: MouseEvent) => {
  const q = gsap.utils.selector(event.target);

  // Text
  gsap.fromTo(
    q('.char'),
    {
      'will-change': 'opacity, transform',
      opacity: 0,
      rotationX: -90,
      yPercent: 50,
    },
    {
      ease: 'power1.inOut',
      opacity: 1,
      rotationX: 0,
      yPercent: 0,
      stagger: {
        each: 0.03,
        from: 0,
      },
    },
  );

  // Number;
  // gsap.to(q('.drawer-menu__item__number'), {
  //   ease: 'power1.inOut',
  //   fontStyle: 'italic',
  //   fontSize: '28px',
  // });
};

// const menuItemMouseEnter = (event: MouseEvent) => {
//   const q = gsap.utils.selector(event.target);

//   // Text
//   gsap.fromTo(
//     q('.char'),
//     {
//       'will-change': 'opacity, transform',
//       opacity: 0,
//       rotationY: 90,
//       // fontStyle: 'normal',
//     },
//     {
//       ease: 'power1.inOut',
//       opacity: 1,
//       rotationY: 0,
//       fontStyle: 'italic',
//       // fontWeight: '500',
//       // letterSpacing: 4,
//       stagger: {
//         each: 0.03,
//         from: 1,
//       },
//     }
//   );

//   // Number
//   gsap.fromTo(
//     q('.drawer-menu__item__number'),
//     {
//       'will-change': 'opacity, transform',
//       opacity: 0,
//       rotationY: 90,
//     },
//     {
//       ease: 'power1.inOut',
//       opacity: 1,
//       rotationY: 0,
//       fontStyle: 'italic',
//       fontSize: '38px',
//     }
//   );
// };

// const menuItemMouseLeave = (event: MouseEvent) => {
//   console.log('event: ', event);

// const q = gsap.utils.selector(event.target);
// Text
// gsap.fromTo(
//   q('.char'),
//   {
//     'will-change': 'opacity, transform',
//     opacity: 0,
//     rotationY: -180,
//   },
//   {
//     ease: 'power1.inOut',
//     opacity: 1,
//     rotationY: 0,
//     fontStyle: 'normal',
//     // fontWeight: '400',
//     // letterSpacing: 0,
//     stagger: {
//       each: 0.04,
//       from: 'edges',
//     },
//   }
// );
// Number
// gsap.to(q('.drawer-menu__item__number'), {
//   ease: 'power1.inOut',
//   opacity: 1,
//   fontStyle: 'normal',
//   fontSize: '18px',
// });
// };

// >>>
</script>

<template lang="pug">
q-layout(style="background-color: #F7F7F7;")
  // Header
  q-header().bg-transparent.fixed
    q-toolbar().row.justify-between.pa-4.bg-transparent
      //- q-img.toolbar__logo(
        src="~assets/logo.svg"
        no-spinner
        @click.stop="router.push('/')").w-150px.h-46px.cursor-pointer

      //- p.text-red {{ isRightDrawerOpen }}
      q-space
      MenuBtn(
        style="z-index: 4000;"
        @click="rightDrawerToggle")
      //- MainMenu.q-mr-md
  // Right Drawer
  //- transition(
    appear
    enter-active-class="animated fadeIn"
    leave-active-class="animated fadeOut")
  q-drawer#right-drawer(
      ref="rightDrawerRef"
      v-model="isRightDrawerOpen"
      side="right"
      overlay
      behavior="mobile"
      :width="width < 600 ? width : 600"
      ).drawer-right
      .drawer-right__container
        q-toolbar.drawer-right__toolbar().row.justify-end.pa-4
          MenuBtn(
            icon="close"
            @click="rightDrawerToggle")
        .drawer-right__menu-container.flex.flex-center.items-center.w-full.mt-4
          q-list.drawer-menu().alegreya-400.w-full
            q-item.drawer-menu__item(
              v-for="(item,i) in menuItems"
              :key="`drawer-menu-item-${item.name}-${i}`"
              ref="drawerMenuItemRef"
              :to="item.path"
              active-class="drawer-menu__item--active"
              clickable
              @mouseenter="menuItemMouseEnter0"
              ).px-48px.py-4.lg-px-80px
              q-item-section
                //- span.char {{ item.label }}
                SplitSpanLetters.drawer-menu__item__label(
                  :letters="item.label"
                  item-class="char")
                  //- :item-class="`char-${i}`")
              q-item-section(side)
                .w-48px.text-center
                  span.drawer-menu__item__number ({{ i+1 }})
          .drawer-right__social-btns().mt-16
            EmailLink(
              @click="$yandexMetrika.reachGoal('menu_click__email')"
              ).text-white
            //- SocialBtns

  // Pages
  q-page-container
    Suspense
      router-view

  // Footer
  //- q-footer().bg-transparent
    q-toolbar
      MenuBar.gt-md

  // Dialogs
  FormDialog
  SuccessDialog
</template>

<style lang="sass">


// (style="height: calc(100dvh - 80px)")

// .q-drawer--right
//   widht: 100% !important
//   max-width: 600px !important


.drawer-right
  max-height: 100dvh
  background: linear-gradient(322.02deg, #211F20 28.08%, #5C565B 71.92%)
  // background-color: #1f2122

.q-drawer.q-drawer--right
  // width: 600px; transform: translateX(0px);
  // transition: all 0.6s ease-out !important
  // transition: all 0.6s ease-in !important
  transition: all 0.8s !important


.drawer-menu
  font-family: Alegreya
  font-size: 40px
  font-weight: 400
  line-height: 40px
  text-align: left

// .q-focusable:focus, .q-manual-focusable--focused, .q-hoverable:hover
//     > .q-focus-helper
//     background-color: #1f2122 !important
//     background: #1f2122 !important

.drawer-menu__item
  color: white

.drawer-menu__item__label
  // perspective: 2000px
  font-size: 40px
  // font-size: 64px



// .drawer-menu__item:hover
//   background-color: #1f2122 !important

.drawer-menu__item--active.q-router-link--exact-active
  color: $primary
  .drawer-menu__item__number
    color: $primary

.drawer-menu__item__number
  font-family: Alegreya
  font-size: 18px
  font-weight: 400
  line-height: 18px
  color: white
</style>
